import { forEach } from 'lodash'

const urlLinkOpen = function (e) {
  if (e.target.innerText !== '' && e.target.innerText.indexOf('http') === 0) {
    window.open(e.target.innerText)
  }
}
const urlLinkEvent = function (el) {
  const filteredMutationList = el.querySelectorAll('.urllink')
  if (filteredMutationList && filteredMutationList.length > 0) {
    forEach(filteredMutationList, function (relm) {
      console.log('relm', relm)
      relm.removeEventListener('click', urlLinkOpen)
      relm.addEventListener('click', urlLinkOpen)
    })
  }
}

let observer = null
const urlLinkDirective = {
  name: 'urlLink',
  deep: true,
  mounted: function (el, binding) {
    console.log('binding', binding)
    urlLinkEvent(el)
    observer = new MutationObserver(function (mutations) {
      const filteredMutationList = el.querySelectorAll('.urllink')
      if (filteredMutationList && filteredMutationList.length > 0) {
        console.log('mutations: ', mutations, filteredMutationList)
        urlLinkEvent(el)
      }
    })
    observer.observe(el, {
      // attributes: true,
      childList: true,
      characterData: true,
      subtree: true
    })
  },
  unmounted: function () {
    if (observer !== null) {
      observer.disconnect()
      observer = null
    }
  }
}

export default urlLinkDirective
