<template>
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
  <modals-container></modals-container>
  <ui-loading></ui-loading>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
import { UiLoading } from '$$components'
import { useHead } from 'vue3-head' // , setTitle, setMeta

import { reactive, computed } from 'vue'
import 'vuetify/styles'
export default {
  name: 'App',
  components: {
    UiLoading
  },
  setup() {
    const { mobile } = useDisplay()
    const { setLocaleMessage } = useI18n()

    const headOptions = reactive({
      title: '두부분식',
      description: '두부 멘탈 극복 프로젝트 두부분식',
      keywords: '두부, 분식, 투자, 커뮤니티',
      type: 'website',
      url: 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/',
      image: 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/assets/main/dobu-share.png'
    })

    useHead({
      title: computed(() => headOptions.title),
      meta: [
        { name: 'description', content: computed(() => headOptions.description) },
        { name: 'keywords', content: '두부, 분식, 투자, 커뮤니티' }
      ],
      og: [
        { property: 'og:type', content: 'website' },
        { property: 'og:title', content: computed(() => headOptions.title) },
        { property: 'og:description', content: computed(() => headOptions.description) },
        { property: 'og:url', content: computed(() => headOptions.url) },
        { property: 'og:image', content: computed(() => headOptions.image) }
      ]
    })

    return {
      mobile,
      headOptions,
      setLocaleMessage
    }
  },
  data() {
    return {}
  },
  watch: {
    $route: {
      handler: function (nVal) {
        console.log('[metaInfo] watch', nVal)
        this.metaInfo()
      },
      deep: true
    }
  },
  async mounted() {
    // 메세지 공통 처리
    console.log('mobile', this.mobile) // false
    if (this.mobile) {
      this.$pinia.popup.asideActive = false
      this.$pinia.popup.mode = 'mobile'
      console.log('this.$pinia.popup.asideActive', this.$pinia.popup.asideActive) // false
    } else {
      // this.$pinia.popup.asideActive = true
      this.$pinia.popup.mode = 'pc'
    }
  },
  errorCaptured(err, vm, info) {
    console.log('[error]', err, vm, info)
  },
  methods: {
    metaInfo() {
      const title = this.$route.meta.title + ' | 두부분식'
      const description = this.$route.meta.description
      const url = 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr' + this.$route.fullPath

      this.headOptions.title = title
      this.headOptions.description = description
      this.headOptions.url = url
      this.headOptions.image = 'https://' + import.meta.env.VUE_APP_DOMAIN_PC_PREFIX + '.dobu.kr/assets/main/dobu-share.png'
    }
  }
}
</script>
<style lang="scss">
// @import './styles/settings/vuetify';
// @import './styles/settings/VCard';
// @import './styles/settings/settings';
@import '@/styles/overrides';
</style>
