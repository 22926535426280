<template>
  <v-app-bar class="header">
    <div class="header-menu-left">
      <button class="prev-btn" title="이전단계" @click="goBack()"></button>
      <p class="menu-tit">{{ menuName }}</p>
    </div>
  </v-app-bar>
</template>
<script>
import { isEmpty } from 'lodash'
export default {
  name: 'BackHeader',
  components: {},
  data() {
    return {
      tabs: 'one',
      pageName: ''
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.popup.mode === 'mobile'
    },
    headerName() {
      if (this.isMobile) {
        return '두부분식'
      } else {
        return '세상에 이런 폰트가 나오다니 천재인듯'
      }
    },
    nickNm() {
      return this.$pinia.com.followNickNm
    },
    menuName() {
      let title = ''
      let pageInfo = this.$router.currentRoute.value.meta

      if (this.pageName === 'CO0104M01') {
        title = this.nickNm
        // isEmpty(this.nickNm) ? pageInfo.title : this.nickNm
      } else {
        title = pageInfo.title
      }
      return title
    },
    isAuth() {
      let isAuth = this.$pinia.auth.isAuth
      return isAuth
    }
  },
  watch: {
    $route: {
      handler: function (nVal) {
        this.pageName = nVal.name
      },
      deep: true
    }
  },
  mounted() {
    this.pageName = this.$route.name
  },
  methods: {
    activeAsideClick() {
      this.$pinia.popup.asideActive = !this.$pinia.popup.asideActive
    },
    movePage(path, name) {
      console.log('[pageMove]', path)
      if (!isEmpty(path)) {
        this.$router.push({ path: path })
      } else {
        this.$router.push({ name: name, query: {}, state: {} })
      }
    },
    goBack() {
      let history = ''
      const currentRoute = this.$router.currentRoute._value
      try {
        history = this.$router.options.history.state.back
      } catch (error) {
        this.movePage('/')
      }
      if (isEmpty(history)) {
        if (currentRoute.name === 'PR0200M01') {
          this.$router.replace({ path: '/PR/PR0100M01' })
        } else {
          this.movePage('/')
        }
      } else if ((currentRoute.name === 'CO0101M02' || currentRoute.name === 'CO0102M02') && history === currentRoute.fullPath) {
        this.$router.go(-2)
      } else {
        this.$router.go(-1)
      }
      /* 
      else if (currentRoute.name === 'CO0104M01') {
        this.$pinia.com.followNickNm.splice(0, 1)
        this.$router.go(-1)
      }
      */
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/settings/reset';
</style>
