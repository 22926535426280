// Vue App 생성 함수를 선언합니다.
import { createApp } from 'vue'
// store로 사용할 pinia 생성 함수를 선언합니다.
import { createPinia } from 'pinia'
// 메인 App를 선언합니다
import App from './App.vue'
// 네비게이션을 담당하는 라우터를 선업합니다
import router from './router'
// pinia를 사용하는 스토어 기능들을 선언합니다
import { useAuthStore, useLoadingStore, useMenuStore, useRouteStore, usePopupStore, useComStore } from './store'
// 커스텀 디렉티브를 선언한다
import CommonDirectives from '$$plugins/CommonDirectives'
// vuetify에서 사용하는 아이콘 스타일을 선언합니다
import '@mdi/font/css/materialdesignicons.css'
// vuetufy에서 사용하는 스타일을 선언합니다
// import 'vuetify/styles'
// vuetify 객체를 선언합니다
import { createVuetify } from 'vuetify'
// 다국어 모듈을 선언합니다
import { createI18n, useI18n } from 'vue-i18n'
// vuetify에 다국어 적용 어뎁터를 선언합니다
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
// vuetify애 선언된 다국어 정보를 선언합니다
import { ko, en } from 'vuetify/locale'
// vuetify에 선언된 모든 컴퍼넌트를 목록을 선언합니다
import * as components from 'vuetify/components'
// labs vuetify에 선언된 모든 컴퍼넌트를 목록을 선언합니다
import * as labsComponents from 'vuetify/labs/components'
// vuetify애 선언된 모든 디렉티브를 선언합니다
import * as directives from 'vuetify/directives'
// 화면 전환 시 로그인/네비게이션/기타 공통 기능을 처리한 가드를 선언합니다
import AuthenticationGuardPlugin from '$$plugins/AuthenticationGuard'
import I18nPlugin from '$$plugins/I18nPlugin'
// 파이널 모달을 선언합니다
import VueFinalModal from './components/base/modal/lib'
// 파이널 모달을 프로젝트 내에서 사용할 형태로 선언합니다
import ModalPlugin from '$$plugins/Modal'
// 프로젝트에서 사용할 공통기능을 선언합니다
import CommonPlugin from '$$plugins/Common'
// 프로젝트에서 사용할 공통기능을 선언합니다
import commonCodePlugin from '$$plugins/CommonCode'
// XSS를 방어할 v-html 대상 사용할 디렉티브를 선언합니다
import VueDOMPurifyHTML from 'vue-dompurify-html'
// 외부 스크립트를 동적으로 로드하는 기능을 선언합니다
import AsyncLoadScriptPlugin from '$$plugins/AsyncLoadScript'
// 서버와 통신 시 공통으로 처리해야할 기능을 선언합니다
import AxiosPlugin from '$$plugins/Axios'
// 프로젝트 내 로딩바를 컨트롤 하는 공통 기능을 선언합니다
import LoadingPlugin from '$$plugins/Loading'
// google analytics긴을 선언합니다
// import VueGtag from 'vue-gtag'
// 앱과 웹간에 통신 규약을 선언합니다
import NativeInterfacePlugin from '$$plugins/NativeInterfacePlugin'
// 프로젝트가 수행되는 환경을 조사하는 공통기능을 선언합니다
import DevicePlugin from '$$plugins/Device'
// HTML Editor를 선언합니다
import CKEditor from '@ckeditor/ckeditor5-vue'
// 메타정보를 관리하는 기능을 선언합니다
// import { createMetaManager } from 'vue-meta'
import { createVue3Head } from 'vue3-head'
// 다국어 기능에 사용할 언어팩을 선언합니다
import koJson from '@/locales/ko.json'
import enJson from '@/locales/en.json'
// i18n에 사용될 언어팩을 메세지에 선언합니다

const messages = {
  ko: {
    ...koJson,
    $vuetify: {
      ...ko
    }
  },
  en: {
    ...enJson,
    $vuetify: {
      ...en
    }
  }
}
// 다국어 기능을 선언합니다
const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: 'ko',
  fallbackLocale: 'en',
  globalInjection: true,
  warnHtmlMessage: false,
  messages
})

// vuetify 기능을 선언합니다
const myCustomLightTheme = {
  dark: true,
  colors: {
    background: '#1A1A1A',
    surface: '#1A1A1A',
    primary: '#6F48E6',
    secondary: '#444444',
    gray: '#979797',
    error: '#F1807B',
    blue: '#12617B',
    teal: '#2E6348',
    brown: '#4D3535',
    lime: '#4E522C',
    indigo: '#1B3662',
    green: '#395729',
    purple: '#5C3DB6',
    'gray-darken-1': '#2D2C2C',
    'gray-darken-2': '#444444',
    'gray-darken-3': '#323337',
    'gray-darken-4': '#1A1A1A',
    'gray-lighten-2': '#D7D6D6'
  }
  // variables: {
  //   'border-color': '#000000',
  //   'border-opacity': 0.12,
  //   'high-emphasis-opacity': 0.87,
  //   'medium-emphasis-opacity': 0.6,
  //   'disabled-opacity': 0.38,
  //   'idle-opacity': 0.04,
  //   'hover-opacity': 0.04,
  //   'focus-opacity': 0.12,
  //   'selected-opacity': 0.08,
  //   'activated-opacity': 0.12,
  //   'pressed-opacity': 0.12,
  //   'dragged-opacity': 0.08,
  //   'theme-kbd': '#212529',
  //   'theme-on-kbd': '#FFFFFF',
  //   'theme-code': '#F5F5F5',
  //   'theme-on-code': '#000000'
  // }
}
// vuetify 기능을 선언합니다
const vuetify = createVuetify({
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  },
  components: {
    ...components,
    ...labsComponents
  },
  directives,
  icons: {
    iconfont: 'mdi' // 'md' || 'mdi' || 'fa' || 'fa4'
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n })
  }
})
// pinia 스토어를 선언합니다
const pinia = createPinia()
// 새로고침 시 스토어를 백업/복원하는 기능을 선언합니다
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
pinia.use(piniaPluginPersistedstate)
// 프로젝트 메인 앱을 만듭니다
const app = createApp(App)
// 공통코드 사용
app.use(commonCodePlugin, router, pinia)
app.use(AuthenticationGuardPlugin, router, pinia)
// 커스텀 디렉티브 사용
app.use(CommonDirectives)
// 다국어 사용
app.use(I18nPlugin, i18n)
app.use(i18n)
// 스토어 사용
app.use(pinia)
const authStore = useAuthStore()
const loadingStore = useLoadingStore()
const menuStore = useMenuStore()
const routeStore = useRouteStore()
const popupStore = usePopupStore()
const comStore = useComStore()
//스토어 추가
pinia.auth = authStore
pinia.loading = loadingStore
pinia.menu = menuStore
pinia.route = routeStore
pinia.popup = popupStore
pinia.com = comStore
// 모달 사용
app.use(
  VueFinalModal({
    componentName: 'VueFinalModal',
    key: '$modal',
    dynamicContainerName: 'ModalsContainer'
  })
)
app.use(
  ModalPlugin,
  VueFinalModal({
    componentName: 'VueFinalModal',
    key: '$modal',
    dynamicContainerName: 'ModalsContainer'
  }),
  pinia,
  router
)
// 통신 규약 사용
console.log('main import.meta.env.VUE_APP_USE_E2E_WEB', import.meta.env.VUE_APP_USE_E2E_WEB)
app.use(AxiosPlugin, router, pinia, {
  e2eWebUrl: import.meta.env.VUE_APP_USE_E2E_GW,
  useE2eWeb: import.meta.env.VUE_APP_USE_E2E_WEB === 'true'
})
// 로딩바 공통 사용
app.use(LoadingPlugin, pinia)
// 구글 어날리틱스 사용
// app.use(
//   VueGtag,
//   {
//     config: {
//       id: 'G-PEERK8VKSL'
//     }
//   },
//   router
// )
// 공통기능 사용
app.use(CommonPlugin, pinia)
// 스크립트 동기 로드 사용
app.use(AsyncLoadScriptPlugin)
// 네이티브 앱 인터페이스 사용
app.use(NativeInterfacePlugin)
// 디바이스 조사 기능 사용
app.use(DevicePlugin)
// XSS 보안취약점 방어 기능 사용
app.use(VueDOMPurifyHTML, {
  default: {
    FORBID_TAGS: ['form', 'script', 'a', 'input', 'iframe', 'object', 'applet', 'embed', 'meta'],
    FORBID_ATTR: ['style']
  }
})
// vurify 사용
app.use(vuetify)
// 라우터 사용
app.use(router)
// 메타 기능 사용
app.use(createVue3Head())
// HTML Editor를 사용
app.use(CKEditor)
// DOM 에 기능 추가
app.mount('#app')
