<template>
  <v-layout fluid>
    <router-view name="aside"></router-view>
    <router-view name="header"></router-view>
    <v-main id="mainItem">
      <v-tabs-window v-model="tabs">
        <v-tabs-window-item id="indexItem" v-resize="resizeCont" value="one">
          <router-view v-slot="{ Component }" :key="$route.fullPath" name="content">
            <component :is="Component" ref="content"></component>
          </router-view>
        </v-tabs-window-item>
        <!-- <v-tabs-window-item value="two"> </v-tabs-window-item>
        <v-tabs-window-item value="three"> </v-tabs-window-item> -->
      </v-tabs-window>
      <router-view name="footer"></router-view>
      <router-view id="quickMenu" v-mutate="resetSize" name="quick" :width-obj="widthObj"></router-view>
      <!-- <router-view name="footerNav"></router-view> -->
    </v-main>
  </v-layout>
</template>

<script>
import { isEmpty } from 'lodash'
export default {
  name: 'IndexPage',
  components: {},
  setup() {},
  data() {
    return {
      tabs: 'one',
      widthObj: { id: '', widthSize: 0 },
      widthSize: 1
    }
  },
  computed: {
    tabName() {
      return this.$pinia.menu.tabName
    },
    routeName() {
      return this.$route.name
    }
  },
  watch: {
    tabName(val) {
      this.tabs = val
    }
  },
  mounted() {},
  methods: {
    resizeCont() {
      const width = isEmpty(document.getElementById('quickMenu')) ? 0 : document.getElementById('indexItem').clientWidth
      this.widthSize++
      this.widthObj = { id: 'resize' + this.widthSize, widthSize: width }
    },
    resetSize() {
      this.widthObj = { id: 'reset' + this.widthSize, widthSize: document.getElementById('indexItem').clientWidth }
      this.widthSize++
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/settings/reset';
</style>
