import {
  IndexComPage,
  IndexContentPage,
  ComMainLayoutComponets,
  CommunityDefaultLayoutComponets,
  ComWriteLayoutComponets,
  ComDetailLayoutComponets,
  ComSearchLayoutComponets,
  backLayoutComponets
} from '@/views/layout'
const routes = [
  {
    path: 'CO',
    component: IndexComPage,
    children: [
      {
        path: 'CO0100M01',
        name: 'CO0100M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0100M01.vue') }, ComMainLayoutComponets),
        meta: { requiresAuth: false, title: '커뮤니티', description: '커뮤니티 index' }
      },
      {
        path: 'CO0101M00',
        name: 'CO0101M00',
        components: Object.assign(
          { content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0101M00.vue') },
          CommunityDefaultLayoutComponets
        ),
        meta: { requiresAuth: true, title: '핀플채널', description: '핀플채널(내 팔로잉)' }
      },
      {
        path: 'CO0101M01/:id',
        name: 'CO0101M01',
        components: Object.assign(
          { content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0101M01.vue') },
          CommunityDefaultLayoutComponets
        ),
        meta: { requiresAuth: false, title: '핀플채널', description: '핀플채널(전체)' }
      },
      {
        path: 'CO0101M02',
        name: 'CO0101M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0101M02.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: false, title: '핀플채널', description: '핀플채널 상세' }
      },
      {
        path: 'CO0101M03',
        name: 'CO0101M03',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0101M03.vue') }, ComWriteLayoutComponets),
        meta: { requiresAuth: true, title: '의견', description: '핀플채널 글쓰기' }
      },
      {
        path: 'CO0101M05',
        name: 'CO0101M05',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0101M05.vue') }, ComSearchLayoutComponets),
        meta: { requiresAuth: false, title: '게시글검색', description: '검색', type: 'POST' }
      },
      {
        path: 'CO0102M02',
        name: 'CO0102M02',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0102M02.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: false, title: '오픈톡', description: '오픈톡 상세' }
      },
      {
        path: 'CO0102M03',
        name: 'CO0102M03',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0102M03.vue') }, ComWriteLayoutComponets),
        meta: { requiresAuth: true, title: '의견', description: '오픈톡 글쓰기' }
      },
      {
        path: 'CO0102M01',
        name: 'CO0102M01',
        components: Object.assign(
          { content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0102M01.vue') },
          CommunityDefaultLayoutComponets
        ),
        meta: { requiresAuth: false, title: '오픈톡', description: '오픈톡 목록' }
      },
      {
        path: 'CO0103M00',
        name: 'CO0103M00',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0103M00.vue') }, ComMainLayoutComponets),
        meta: { requiresAuth: false, title: '핀플루언서', description: '핀플루언서' }
      },
      {
        path: 'CO0103M01',
        name: 'CO0103M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0103M01.vue') }, ComSearchLayoutComponets),
        meta: { requiresAuth: false, title: '핀플루언서', description: '핀플루언서 검색' }
      },
      {
        path: 'CO0104M00',
        name: 'CO0104M00',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0104M00.vue') }, ComDetailLayoutComponets),
        meta: { requiresAuth: false, title: '활동로그', description: '활동로그' }
      },
      {
        path: 'CO0104M01',
        name: 'CO0104M01',
        components: Object.assign({ content: () => import(/* webpackChunkName: "play_CO" */ '@/views/CO/CO0104M01.vue') }, backLayoutComponets),
        meta: { requiresAuth: false, title: '', description: '활동로그 팔로잉/팔로워' }
      }
    ]
  },
  {
    path: 'CO',
    component: IndexContentPage,
    children: []
  }
]
export default routes
