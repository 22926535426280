import { urlLinkDirective } from '@/directives'

// 20190919 toggleBtnDirective 추가
const CommonDirectives = {
  install: Vue => {
    // Dom에 명시된 urllink class에 이벤트를 부여한다
    Vue.directive(urlLinkDirective.name, urlLinkDirective)
  }
}

export default CommonDirectives
